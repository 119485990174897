$(document).foundation();


  // hide #back-to-top first
  $("#back-to-top").hide();

  // fade in #back-to-top
  $(function () {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#back-to-top').fadeIn();
      } else {
        $('#back-to-top').fadeOut();
      }
    });

  });


  $("[data-menu-underline-from-center] a").addClass("underline-from-center");
